import React from 'react'
import { ShellProps } from '../src/types/shell'
import projectNames from '../src/configs/projectNames'
import TrafficDirector from '../src/TrafficDirector'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root(props) {
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <TrafficDirector {...props} />
    </section>
  )
}
