import React, { useCallback, useMemo, useState } from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import { createBrowserHistory } from 'history'
import { EMPTY_ARROW_FUNCTION } from './Constants'

export const ConfigContext = React.createContext({
  stack: null,
  navigation: null,
  localization: null,
  paas: null,
  printer: null,
  sku: null,
  printerUseChoice: null,
  tenantType: null,
  os: null,
  isBrowserSupported: null,
  isOsSupported: null,
  showInstallButton: null,
  setPrinterUseChoice: EMPTY_ARROW_FUNCTION,
  setTenantType: EMPTY_ARROW_FUNCTION,
  getStoreData: EMPTY_ARROW_FUNCTION,
  setStoreData: EMPTY_ARROW_FUNCTION,
  publishCdmEvent: EMPTY_ARROW_FUNCTION,
  header: {
    setHeaderModelName: EMPTY_ARROW_FUNCTION,
    selectedTab: 0,
    setSelectedTab: EMPTY_ARROW_FUNCTION,
    previousSelectedTab: 0,
    selectedTabItem: {},
    setSelectedTabItem: EMPTY_ARROW_FUNCTION
  },
  footer: {
    setNextButtonLabel: EMPTY_ARROW_FUNCTION,
    setFooterNavVisibility: EMPTY_ARROW_FUNCTION,
    updateCurrentRoute: EMPTY_ARROW_FUNCTION,
    setCustomNavigation: EMPTY_ARROW_FUNCTION,
    nextRoute: EMPTY_ARROW_FUNCTION,
    setNextDisabled: EMPTY_ARROW_FUNCTION
  },
  sidebar: {
    setSidebarVisibility: EMPTY_ARROW_FUNCTION,
    setCustomSidebar: EMPTY_ARROW_FUNCTION
  },
  currentCustomNavigation: null,
  isHpPlus: null,
  isHpPlusCountry: null,
  cachedCMS: new Map(),
  currentPath: null,
  isMobile: null,
  isLoaded: null,
  compatibilityDefinition: null,
  error: null,
  setError: EMPTY_ARROW_FUNCTION
})

const LOCAL_STORAGE_KEY = 'traffic-director'

const _getStoreData = (subkey = null) => {
  const text = localStorage.getItem(LOCAL_STORAGE_KEY)
  let json = text ? JSON.parse(text) : {}
  if (!subkey) {
    return json
  }
  return json[subkey]
}

const _setStoreData = (subkey, value) => {
  const json = _getStoreData()
  json[subkey] = value
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(json))
}

const ConfigProvider = (props) => {
  const {
    header = {
      setHeaderModelName: EMPTY_ARROW_FUNCTION,
      previousSelectedTab: 0,
      selectedTab: 0,
      setSelectedTab: EMPTY_ARROW_FUNCTION,
      selectedTabItem: {},
      _setSelectedTabItem: EMPTY_ARROW_FUNCTION
    },
    sidebar = {
      setSidebarVisibility: EMPTY_ARROW_FUNCTION,
      setCustomSidebar: EMPTY_ARROW_FUNCTION
    },
    footer = {
      setNextButtonLabel: EMPTY_ARROW_FUNCTION,
      setFooterNavVisibility: EMPTY_ARROW_FUNCTION,
      updateCurrentRoute: EMPTY_ARROW_FUNCTION,
      setCustomNavigation: EMPTY_ARROW_FUNCTION,
      nextRoute: EMPTY_ARROW_FUNCTION,
      setNextDisabled: EMPTY_ARROW_FUNCTION,
      currentCustomNavigation: null
    },
    stack = Stack.pie,
    localization = {
      language: 'en',
      country: 'US',
      getLanguages: EMPTY_ARROW_FUNCTION
    },
    analytics,
    localStore,
    sku,
    printer,
    paas,
    pageDataCache,
    tenantType,
    setTenantType,
    os,
    isBrowserSupported,
    isOsSupported,
    showInstallButton,
    currentPath,
    isHpPlus,
    isHpPlusCountry,
    isMobile,
    compatibilityDefinition,
    error,
    setError,
    flags
  } = props

  const setStoreData = localStore?.setStoreData || _setStoreData
  const getStoreData = localStore?.getStoreData || _getStoreData

  const country = localization.country.toUpperCase()
  const language = localization.language
  const enabled = localization.enabled
  const [supportedProductsQuery, setSupportedProductsQuery] = useState(null)
  const [connectionType, setConnectionType] = useState(null)
  const [driverDownloadSuccess, setDriverDownloadSuccess] = useState(false)
  const [selectedAccordionItems, _setSelectedAccordionItems] = useState({})
  const isLoaded = useMemo(() => !!sku && !!printer, [sku, printer])

  const setSelectedAccordionItems = useCallback(
    (accordionName, expanded) => {
      _setSelectedAccordionItems((prevAccordionItem) => {
        return {
          ...prevAccordionItem,
          [accordionName]: expanded
        }
      })
    },
    [selectedAccordionItems]
  )

  const history = createBrowserHistory({
    basename: enabled ? `/${country.toLowerCase()}/${language}` : `/`
  })
  const [navigation] = useState(props.navigation ? props.navigation : history)

  let publishCdmEvent = EMPTY_ARROW_FUNCTION
  if (analytics?.publishCdmEvents) {
    publishCdmEvent = (events, metadata) => {
      events = Array.isArray(events) ? events : [events]
      metadata
        ? analytics.publishCdmEvents(events, metadata)
        : analytics.publishCdmEvents(events)
    }
  }

  const configState = useMemo(() => {
    return {
      stack,
      navigation,
      localization,
      paas,
      printer,
      sku,
      tenantType,
      supportedProductsQuery,
      os,
      isBrowserSupported,
      isOsSupported,
      showInstallButton,
      setTenantType,
      setSupportedProductsQuery,
      connectionType,
      setConnectionType,
      driverDownloadSuccess,
      setDriverDownloadSuccess,
      getStoreData,
      setStoreData,
      publishCdmEvent,
      header,
      footer,
      sidebar,
      currentCustomNavigation: footer.currentCustomNavigation,
      selectedAccordionItems,
      setSelectedAccordionItems,
      isHpPlus,
      isHpPlusCountry,
      cachedCMS: pageDataCache,
      currentPath,
      isMobile,
      isLoaded,
      compatibilityDefinition,
      error,
      setError,
      flags
    }
  }, [
    stack,
    navigation,
    localization,
    paas,
    printer,
    sku,
    tenantType,
    supportedProductsQuery,
    os,
    isBrowserSupported,
    isOsSupported,
    showInstallButton,
    setTenantType,
    setSupportedProductsQuery,
    connectionType,
    setConnectionType,
    driverDownloadSuccess,
    setDriverDownloadSuccess,
    getStoreData,
    setStoreData,
    publishCdmEvent,
    header,
    footer,
    sidebar,
    selectedAccordionItems,
    setSelectedAccordionItems,
    isHpPlus,
    isHpPlusCountry,
    pageDataCache,
    currentPath,
    isMobile,
    isLoaded,
    compatibilityDefinition,
    error,
    setError,
    flags
  ])

  return (
    <ConfigContext.Provider value={configState}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
