import React from 'react'

export const PrinterSpecificLandingPage = React.lazy(() =>
  import('./Landing/PrinterSpecificLandingPage')
)
export const CarouselPage = React.lazy(() =>
  import('./Instructions/CarouselPage')
)
export const ConnectPrinterToNetworkPage = React.lazy(() =>
  import('./PrinterConnection/ConnectPrinterToNetworkPage')
)
export const PrinterUseChoicePage = React.lazy(() =>
  import('./PrinterUseChoicePage')
)
export const SetupChecklistPage = React.lazy(() =>
  import('./SetupChecklist/SetupChecklistPage')
)
export const ConnectUsbPage = React.lazy(() =>
  import('./SetupChecklist/ConnectUsbPage')
)
export const UnsupportedOsPage = React.lazy(() => import('./UnsupportedOsPage'))
export const InstallDriverPage = React.lazy(() =>
  import('./SetupChecklist/InstallDriverPage')
)
export const DriverNotFoundPage = React.lazy(() =>
  import('./SetupChecklist/DriverNotFoundPage')
)
export const SelectUsbOnDisplayPage = React.lazy(() =>
  import('./SetupChecklist/SelectUsbOnDisplayPage')
)
export const NotFoundPage = React.lazy(() => import('./Landing/NotFoundPage'))
export const DeltaCodePage = React.lazy(() => import('./DeltaCodePage'))
export const HpSoftwarePage = React.lazy(() => import('./HpSoftwarePage'))
export const FinishSetupBusinessPage = React.lazy(() =>
  import('./FinishSetupBusinessPage')
)
export const SkipInternetConnectionPage = React.lazy(() =>
  import('./PrinterConnection/SkipInternetConnectionPage')
)
export const StartAirprintPage = React.lazy(() => import('./StartAirprintPage'))
export const StartPrintingMobilePage = React.lazy(() =>
  import('./StartPrintingMobilePage')
)
export const SubtabPage = React.lazy(() => import('./Subtabs/SubtabPage'))
export const InstallPage = React.lazy(() => import('./install/InstallPage'))
export const SearchPage = React.lazy(() => import('./Landing/SearchPage'))
